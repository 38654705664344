import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../css/style.css";
import placeholder from "../images/placeholder.png";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const EventList = ({ data, pageContext, path }) => {
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const post = data.allWordpressPage.edges[0].node;

  const metatitle = post.acf.meta_title;
  const metadescription = post.acf.meta_description;
  const focus_keyphrase = post.acf.focus_keyphrase;
  const canonical_url = `https://theworldgrad.com${path}`;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const query = `
          query {
            events {
              edges {
                node {
                  id
                   databaseId
                  title
                  slug
                  eventsPost {
                    eventDate
                    eventEndDate
                  }
                  featuredImage {
                    node {
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        `;

        const response = await axios.post('https://theworldgrad.wpengine.com/graphql', { query });
        const events = response.data.data.events.edges.map(edge => edge.node);

        const now = new Date();
        const past = [];
        const future = [];

        events.forEach((event) => {
          const endDate = event.eventsPost.eventEndDate || event.eventsPost.eventDate;
          const eventDate = new Date(endDate);

          if (eventDate < now) {
            past.push(event);
          } else {
            future.push(event);
          }
        });

        const sortedFuture = future.sort((a, b) => new Date(a.eventsPost.eventDate) - new Date(b.eventsPost.eventDate));
        const sortedPast = past.sort((a, b) => new Date(b.eventsPost.eventDate) - new Date(a.eventsPost.eventDate)).slice(0, 3);

        setFutureEvents(sortedFuture);
        setPastEvents(sortedPast);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchEvents();
  }, []);

  const formatDate = (date) => ({
    day: ("0" + date.getDate()).slice(-2),
    month: date.toLocaleString([], { month: "short" }),
    nth: (() => {
      const d = date.getDate();
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    })()
  });

  const renderEventItem = (event) => {
    const eventStartDate = new Date(event.eventsPost.eventDate);
    const eventEndDate = new Date(event.eventsPost.eventEndDate || event.eventsPost.eventDate);

    if (isNaN(eventStartDate.getTime()) || isNaN(eventEndDate.getTime())) {
      console.error('Invalid date:', event.slug);
      return null;
    }

    const startFormatted = formatDate(eventStartDate);
    const endFormatted = formatDate(eventEndDate);

    const eventDateClass = `event-date ${event.eventsPost.eventEndDate ? 'event-end-date' : ''}`;

    return (
      <div className="col-lg-4 col-md-6 pb-5" key={event.slug}>
        <a href={`/event-detail/?id=${event.databaseId}`} className="d-flex h-100">
          <div className="event-sec">
            <div className="event-img-wrap">
              <img
                src={event.featuredImage?.node?.sourceUrl || placeholder}
                alt={event.title}
              />
              <p className={eventDateClass}>
                <span>
                  {startFormatted.day}
                  <span className="nth-lowercase">{startFormatted.nth}</span> {startFormatted.month}
                  {event.eventsPost.eventEndDate && (
                    <>
                      {" "} - {endFormatted.day}
                      <span className="nth-lowercase">{endFormatted.nth}</span> {endFormatted.month}
                    </>
                  )}
                </span>
              </p>
            </div>
            <div className="event-content-wrap p-3">
              <h4
                className="event-title"
                dangerouslySetInnerHTML={{ __html: event.title }}
              ></h4>
            </div>
          </div>
        </a>
      </div>
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        {focus_keyphrase && <meta name="keywords" content={focus_keyphrase} />}
        <link rel="canonical" href={canonical_url} />
        {post.acf.dynamic_schema && (
          <script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>
        )}
      </Helmet>

      <section className="event-listing-sec pt-5">
        <div className="container">
          <h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items}</h1>

          {loading ? (
            <div className="loading-placeholder">Loading events...</div> // Loading placeholder div
          ) : (
            <div className="row">
              {futureEvents.length === 0 ? (
                <div className="col-12 position-relative">
                  <p className="text-center">No upcoming events found. Stay tuned for exciting updates!<br /><br /><br /><br /></p>
                </div>
              ) : (
                futureEvents.map(renderEventItem)
              )}
            </div>
          )}

          <h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items_past}</h1>

          {loading ? (
            <div className="loading-placeholder">Loading past events...</div> // Loading placeholder div
          ) : (
            <div className="row">
              {pastEvents.length === 0 ? (
                <div className="col-12 position-relative">
                  <p className="text-center">No past events available.<br /><br /><br /><br /></p>
                </div>
              ) : (
                pastEvents.map(renderEventItem)
              )}
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query EventQuery {
    allWordpressPage(filter: { wordpress_id: { in: 2499 } }) {
      edges {
        node {
          id
          title
          featured_media {
            source_url
          }
          acf {
            register_link {
              title
              url
              target
            }
            meta_title
            meta_description
            focus_keyphrase
            canonical_url
            dynamic_schema
            banner_label
            label_of_list_of_event_items
            label_of_list_of_event_items_past
            webinar_start_date
            webinar_start_on
          }
        }
      }
    }
  }
`;

export default EventList;
